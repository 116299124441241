
export default {
  data() {
    return {
      
    };
  },
  components: {
    
  },
  created() {},
  methods: {
    to_payment () {
      this.$router.push({
        name: 'admin-test-payment',
      })
    }
  },
  mounted() {
    
  },
  activated() {
    
  },
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
};
